export * from '@oracle-cx-commerce/actions';

// GREN
export * from './gren/send-data-layer';
export * from './gren/gren-pdp-selected-infos';
export * from './gren/gren-global-loader';
export const _getAddress = () => import('./gren/get-address');
export const _getAddressFromZipcode = () => import('./gren/get-address-from-zipcode');
export const _getIp = () => import('./gren/get-ip');
export const _autosugestTypeahead = () => import('./gren/typeahead');
export const _listInventories = () => import('./gren/list-inventories');
export const _uploadDoc = () => import('./gren/uploadDoc');
export const _getHubCombinations = () => import('./gren/get-hub-combinations');
export const _addShipping = () => import('./gren/add-shipping');
export const _updateShipping = () => import('./gren/update-shipping');
export const _getHubStoresRepository = () => import('./gren/hub-stores-repository');
export const _autoSuggestTypeheadB2CCM = () => import('./gren/typeahead');
export const _loginKonviva = () => import('./gren/login-konviva');
export const _getBaseHubCombinations = () => import('./gren/get-base-hub-combinations');
export const GREN_MODAL = () => import('./gren/gren-modal');
export const GREN_CHECKOUT_STEP = () => import('./gren/gren-checkout-steps');
export const _getStoreCredit = () => import('./gren/get-store-credit');
export const _getStoreCreditByCpfCnpj = () => import('./gren/get-store-credit-by-cpf-cnpj');
export const _getBalanceStoreCredit = () => import('./gren/get-balance-store-credit');
export const _getBalanceStoreCreditByCpfCnpj = () => import('./gren/get-balance-store-credit-by-cpf-cnpj');
export const _getValidateBalanceStoreCredit = () => import('./gren/get-validate-balance-store-credit');
export const _getValidateBalanceStoreCreditByCpfCnpj = () =>
  import('./gren/get-validate-balance-store-credit-by-cpf-cnpj');
export const _grenGetTrackCode = () => import('./gren/get-track-code');
export const _grenGetStockStatus = () => import('./gren/gren-get-stock-status');
export const _grenGetInvoice = () => import('./gren/gren-get-invoice');
export const _getProductReview = () => import('./gren/get-product-review');
export const _grenGetEmailIsRegistered = () => import('./gren/get-email-is-registered');
export const _listProductsCustom = () => import('./gren/list-products-custom');
export const _getStockStatusCustom = () => import('./gren/get-stock-status-custom');
export const _sendFacebookEvent = () => import('./gren/send-facebook-event');
export const _listOrdersCustom = () => import('./gren/list-orders-custom');
export const _listSkusCustom = () => import('./gren/list-skus-custom');
export const _listAllSitesOrders = () => import('./gren/list-all-sites-orders');
export const _getAllSitesOrder = () => import('./gren/get-all-sites-order');
export const _getOrderCustom = () => import('./gren/get-order-custom');
export const _getShopBySize = () => import('./gren/get-shop-by-size');
export const _applyCouponsToCart = () => import('./gren/apply-coupons-to-cart');
export const _createProfile = () => import('./gren/create-profile');
export const _updateProfile = () => import('./gren/update-profile');
export const _getAiRecommendations = () => import('./gren/get-ai-recommendations');
export const _getPromotionUpsellMessagesCustom = () => import('./gren/get-promotion-upsell-messages-custom');
export const GREN_IS_VALID_ORDER_CONFIRMATION = () => import('./gren/gren-is-valid-order-confirmation');
export const _getHubInventoryNearest = () => import('./gren/get-hub-inventory-nearest');
